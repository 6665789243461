import Image from "../../assets/images/accounting.png";

const AccessOrg = () => {
  return (
    <div id="access-org">
      <div className="hero-section">
        <h1>
          About<span>Our Funding Approach </span> 
        </h1>
        <p>
        Our foundation operates with a commitment to financial sustainability and growth. Our initial funding phase introduces a 
        protected token(Ifriqiya First Investisment)designed to mitigate inflation risks, ensuring stability and growth for our 
        investments.
        </p><br/>
        </div>
      <div className="hero-image">
        <img src={Image} alt="" className="img-fluid"/>
      </div>
    </div>
  );
};

export default AccessOrg;
