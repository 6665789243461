import Image from "../../assets/images/crypto.png";

const EnterpriceDex = () => {
  return (
    <div id="enterprice-dex">
      <div className="hero-section">
        <h1>
          About 
          <span>Our Initiatives</span> 
        </h1>
        <h3>Mining</h3>
        <p>
        Implementing innovative approaches to sustainable mining practices.
        </p><br/>

        <h3>Recycling Solutions</h3>
        <p>
        Developing advanced technologies to optimize recycling processes.
        </p><br/>

        <h3>Clean Energy Solutions</h3>
        <p>
        Promoting clean energy initiatives to combat climate change.
        </p><br/>

        <h3>Tech Agronomy Solutions</h3>
        <p>
        Innovating in agricultural technologies to enhance productivity and sustainability.
        </p><br/>

        <h3>Academy and Research Center</h3>
        <p>
        Establishing an educational hub and research facility to foster knowledge exchange and innovation.
        </p><br/>
      
        <h3>Health Solutions</h3>
        <p>
        Advancing healthcare through technological innovations and access to essential services.
        </p><br/><br/>
      </div>
      
      <div className="hero-image">
        <img src={Image} alt="" className="img-fluid"/>
      </div>
      <br/>
    </div>
    
  );
};

export default EnterpriceDex;
