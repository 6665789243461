import { AiFillTwitterCircle } from "react-icons/ai";
import { FaTelegram, FaReddit, FaGithub } from "react-icons/fa";
import { AiFillMediumSquare } from "react-icons/ai";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div id="footer">
      <div className="copyright">
        <h3>COPYRIGHT © {year} Ifriqiya Foundation</h3>
      </div>
      <div className="social">
        <ul>
          <li>
            <a href="#">
              <AiFillTwitterCircle />
            </a>
          </li>
          <li>
            <a href="#">
              <FaTelegram />
            </a>
          </li>
          <li>
            <a href="#">
              <AiFillMediumSquare />
            </a>
          </li>
          <li>
            <a href="#">
              <FaReddit />
            </a>
          </li>
          {/* <li>
            <a href="#">
              <FaGithub />
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default Footer;
