import { Counter } from "../../components";
import Image from "../../assets/images/moon.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div id="hero-section">
        <div className="hero-content ">
          <img src={Image} alt="" className="hero-bg" />
          
          <h3>Welcome to</h3>
          <h1>
          Ifriqiya  <span>Foundation</span>
          </h1>
          <p>
          A pioneering blockchain foundation dedicated to tokenizing real-world projects 
          across various sectors. Our mission is to leverage blockchain technology to drive impactful solutions in mining, 
          recycling, clean energy, tech agronomy, education and research, and healthcare.
          </p>
          <div className="cta">
            <a
              href="#"
              className="btn btn-bg"
              target="_blank"
            >
              whitepaper v 1.0 <AiOutlineArrowRight />
            </a>
            {/* <Link to="/buy-eqx" className="btn btn-cr">
              crowdsale
            </Link> */}
          </div>
        </div>
        <div className="hero-image">
          <img src={Image} alt="" />
        </div>
      </div>
    </>
  );
};

export default Home;
