import Image from "../../assets/images/moon.png";
const AboutEqx = () => {
  return (
    <div id="enterprice-dex">
      <div className="hero-section">
        <h1>
          About 
          <span>Ifriqiya Foundation</span> 
        </h1>
        <p>Welcome to Ifriqiya Foundation, a pioneering blockchain foundation dedicated to tokenizing real-world projects across 
          various sectors. Our mission is to leverage blockchain technology to drive impactful solutions in mining, recycling, 
          clean energy, tech agronomy, education and research, and healthcare.</p>

        <h3>Governance and Leadership</h3>
        <p>
        Led by a dedicated team with expertise across various domains, ifriqiya foundation ensures 
        transparent governance and strategic decision-making to achieve our mission and vision.
        </p><br/>

        <h3>Impact in Africa</h3>
        <p>
        Ifriqiya Foundation is focused on driving real economic growth in Africa through our transformative projects and 
        initiatives. By integrating blockchain technology with impactful solutions, we aim to empower communities and contribute 
        to sustainable development across the continent.

        </p><br/>

        <h3>Get Involved</h3>
        <p>
        Join us in revolutionizing industries and creating a positive impact. Whether you're an investor, partner, or supporter 
        of innovative solutions, Ifriqiya Foundation welcomes collaboration and engagement to drive meaningful change.
        </p><br/>

        
      </div>
      
      <div className="hero-image">
        <img src={Image} alt="" className="img-fluid"/>
      </div>
      <br/>
    </div>
  );
};

export default AboutEqx;
